<template>
  <div class="container" align="center">
    <div class="inner-container login">
      <img class="emojise-logo" src="@/assets/images/logo.png"  alt="Logo">
      <h2 class="top-header">Welcome back 👋</h2>
      <span>Log in to your account</span>
      <form @submit.prevent="handleLogin">
        <input type="email" placeholder="Email Address" v-model="email">
        <input type="password" placeholder="Password" v-model="password">
        <div align="right"><span class="forgotten-password" @click="handlePassword">Forgotten Password?</span></div>
        <div v-if="error" class="error">{{ error }}</div>
        <button v-if="!isLoading">Log In</button>
        <button v-else disabled><img src="@/assets/images/spinner.svg" alt="Loading..."></button>
      </form>
      <!-- <p>No Account? <router-link :to="{ name: 'Signup' }" style="color: var(--link);">Create Account</router-link></p> -->
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import useAuth from '@/composables/useAuth'
import { Swal } from '@/composables/useSwal'

export default {
  setup() {
    const router = useRouter()
    const { login, passReset, error, isLoading } = useAuth()
    const email = ref('')
    const password = ref('')

    const handleLogin = async () => {
      await login(email.value, password.value)

      if (!error.value) {
        router.push({ name: 'Queue' })
      }
    }

    const handlePassword = () => {
      Swal.fire({
        title: 'Reset Password 🔒',
        text: 'Forgotten password? Just enter your email address and we\'ll send you a reset link.',
        input: 'email',
        inputPlaceholder: 'Enter your email address',
        showCancelButton: true,
        confirmButtonText: 'Reset Password',
        showLoaderOnConfirm: true
      }).then(async (result) => {
        if (result.isConfirmed) {
          await passReset(result.value)
        }
      })
    }

    return { email, password, handleLogin, handlePassword, error, isLoading }
  }
}
</script>

<style scoped>
  .emojise-logo {
    margin: 20px auto;
  }
  .forgotten-password {
    color: var(--link);
    cursor: pointer;
  }
  button {
    margin: 35px auto 0 auto;
  }
</style>